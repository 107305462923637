<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
          <el-col>
            <el-col class="box">
              <div>
                 <el-button @click="Datestatistics('DAY')" :class="repairDate === 'DAY' ? 'bg-gradient' : ''" >今日</el-button>
                 <el-button @click="Datestatistics('WEEK')" :class="repairDate === 'WEEK' ? 'bg-gradient' : ''" >本周</el-button >
                 <el-button @click="Datestatistics('MONTH')" :class="repairDate === 'MONTH' ? 'bg-gradient' : ''" >本月</el-button>
                 <el-button @click="Datestatistics('YEAR')" :class="repairDate === 'YEAR' ? 'bg-gradient' : ''">本年</el-button>
              </div>
              <div class="Workordernumber">
                <div class="quantity" style="margin-left: 0px">
                  <div>
                    <img src="@/assets/lALPBG1Q6Q5VU2w8PA_60_60.png" />
                  </div>
                  <div class="Workordernumbers">
                    <p>工单总数</p>
                    <p>{{ datas.totalCount }}</p>
                  </div>
                </div>
                <div class="quantity">
                  <div>
                    <img src="@/assets/lALPBG1Q6Q5VU2w8PA_60_60.png" />
                  </div>
                  <div class="Workordernumbers">
                    <p>紧急工单总数</p>
                    <p>{{ datas.urgentCount }}</p>
                  </div>
                </div>
                <div class="quantity">
                  <div>
                    <img src="@/assets/lALPBG1Q6Q5VU2w8PA_60_60.png" />
                  </div>
                  <div class="Workordernumbers">
                    <p>客服工单总数</p>
                    <p>{{ datas.roomCount }}</p>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col class="boxStatus">
              <div class="status">
                <div class="title">工单状态</div>
                <div class="workorder">
                  <div class="cous" @click="statu('NO_RECEIVED')">
                    <p>待接单工单</p>
                    <span>({{ waiting }})</span>
                  </div>
                  <div class="cous" @click="statu('ORDER_RECEIVED')">
                    <p>处理中工单</p>
                    <span>({{ inhand }})</span>
                  </div>
                  <div class="cous" @click="statu('SERVICE_FINISHED')">
                    <p>已完成工单</p>
                    <span>({{ accomplish }})</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-col>
          <!-- 右边-->
          <el-col>
            <div class="boxs">
              <el-col class="boxStatus">
                <div class="status">
                  <div class="title">运营快捷入口</div>
                  <div class="interlinkage">
                    <!--<div @click="workorder">
                      <img src="@/assets/图像 2.png" />
                      <p>工单管理</p>
                    </div>
                    <div @click="staff">
                      <img src="@/assets/图像 3.png" />
                      <p>人员管理</p>
                    </div>
                    <div @click="journal">
                      <img src="@/assets/图像 5.png" />
                      <p>登录日志</p>
                    </div>-->
                  </div>
                </div>
              </el-col>
              <el-row style="min-width: 1430px">
              <el-col class="statistics">
                <div class="status">
                  <div class="title">工单统计</div>
                  <div class="left">
                    <div class="thismonth">
                      <p class="tiems">{{ dataline }}</p>
                      <p class="figure">{{ week }}</p>
                    </div>
                  </div>
                  <el-row class="order-date">
                    <el-row style="display: flex">
                      <el-col class="m-right-10" style="
                          text-align: center;
                          float: right;
                          margin-top: 10px;
                          position: relative;
                          z-index: 999;
                        ">
                        <el-button-group>
                          <el-button @click="handleOrderDate('DAY')" :class="orderDateTypes === 'DAY' ? 'bg-gradient' : ''">今日</el-button>
                          <el-button @click="handleOrderDate('WEEK')" :class="orderDateTypes === 'WEEK' ? 'bg-gradient' : ''">本周</el-button>
                          <el-button  @click="handleOrderDate('MONTH')" :class="orderDateTypes === 'MONTH' ? 'bg-gradient' : ''" >本月</el-button>
                        </el-button-group>
                      </el-col>
                      <el-col style="float: right;margin-top: 10px; position: relative;z-index: 999;" >
                        <date-packer
                          :datePacker="orderDateVal"
                          @setDatePacker="getOrderDatePacker"></date-packer>
                      </el-col>
                    </el-row>
                  </el-row>
                  <el-row class="order-line-chart">
                    <el-row id="order-line" style="width: 80%; height: 300px; position: relative; left: 15%"></el-row>
                  </el-row>
                </div>
              </el-col>
              <!-- 客房服务雷达图-->
              <el-col class="radar">
                <div class="status">
                  <div class="titles">客房服务雷达图</div>
                  <div class="btn">
                    <el-button  @click="radarmap('DAY')" :class="radarDate === 'DAY' ? 'bg-gradient' : ''" >今日</el-button>
                    <el-button  @click="radarmap('WEEK')" :class="radarDate === 'WEEK' ? 'bg-gradient' : ''" >本周</el-button>
                    <el-button  @click="radarmap('MONTH')" :class="radarDate === 'MONTH' ? 'bg-gradient' : ''" >本月</el-button >
                  </div>
                  <div class="tain">
                    <p>维修</p>
                    <p>
                      <el-radio v-model="radios" label="2"
                        >{{ tenanceCount || 0 }}%</el-radio
                      >
                    </p>
                    <p>{{ tain.totalCount }}</p>
                  </div>
                  <div class="clean">
                    <p>清洁</p>
                    <p>
                      <el-radio v-model="radio" label="1"
                        >{{ roomCounts || 0 }}%</el-radio
                      >
                    </p>
                    <p>{{ cleanKeeping.totalCount }}</p>
                  </div>
                  <div class="unit">
                    <p>单位：</p>
                    <p>单</p>
                  </div>
                  <div
                    v-show="relativeplot.length"
                    id="Theradaroftheservice"
                    style="width: 70%; height: 170px; margin: auto"
                  ></div>
                </div>
              </el-col>
              </el-row>
              <el-row style="min-width: 1430px">
              <el-col class="statistics">
                <div class="status">
                  <div class="title">工程统计</div>
                  <div class="left">
                    <div class="thismonth">
                      <p class="tiems">{{ getdataline }}</p>
                      <p class="figure">{{ totalnumber }}</p>
                    </div>
                  </div>
                  <el-row class="order-date">
                    <el-row style="display: flex; height: auto">
                      <el-col class="m-right-10" style="
                          float: right;
                          margin-top: 10px;
                          position: relative;
                          z-index: 999;
                        " >
                        <el-button-group>
                          <el-button @click="handleOrderDates('DAY')" :class="orderDateTypes === 'DAY' ? 'bg-gradient' : ''" >今日</el-button>
                          <el-button @click="handleOrderDates('WEEK')" :class="orderDateTypes === 'WEEK' ? 'bg-gradient' : ''">本周</el-button>
                          <el-button @click="handleOrderDates('MONTH')" :class="orderDateTypes === 'MONTH' ? 'bg-gradient' : ''">本月</el-button>
                        </el-button-group>
                      </el-col>
                      <el-col style="
                          float: right;
                          margin-top: 10px;
                          position: relative;
                          z-index: 999;
                        " >
                        <date-packer
                          :datePacker="orderDateVal"
                          @setDatePacker="engineering">
                        </date-packer>
                      </el-col>
                    </el-row>
                  </el-row>
                  <el-row class="order-line-chart">
                    <el-row
                      id="project"
                      style="width: 80%; height: 300px; position: relative; left: 15%"
                    ></el-row>
                  </el-row>
                </div>
              </el-col>
              <!-- 员工接单排行榜 -->
              <el-col class="radars">
                <div class="status">
                  <div class="titles">员工接单排行榜</div>
                  <div class="btn">
                    <!-- <el-button  @click="staffs('DAY')">今日</el-button>
                    <el-button  @click="staffs('WEEK')">本周</el-button>
                    <el-button  @click="staffs('MONTH')">本月</el-button> -->
                    <el-button @click="staffs('DAY')" :class="dateOrder === 'DAY' ? 'bg-gradient' : ''">今日</el-button>
                    <el-button @click="staffs('WEEK')" :class="dateOrder === 'WEEK' ? 'bg-gradient' : ''" >本周</el-button>
                    <el-button @click="staffs('MONTH')" :class="dateOrder === 'MONTH' ? 'bg-gradient' : ''" >本月</el-button >
                  </div>
                  <el-table :data="tableData" style="width: 100%" height="230">
                    <el-table-column prop="date" label="序号">
                      <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="receiveName" label="姓名"></el-table-column>
                    <el-table-column prop="totalCount" label="接单"></el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                          <el-button type="text" style="color: #F56C6C;" @click="handleDetail('Home', scope.row)" >
                              <el-link type="primary">明细</el-link>
                          </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
     </el-row>
  </section>
</template>

<script>
import { urlObj } from "@/api/interface";
import { mapState } from 'vuex'
import {hotelMan} from "@/api/interface/oms";
export default {
   data() {
      return {
         orderDateType: "WEEK",
         orderDateTypes: "WEEK",
         orderBeginTime: "",
         orderEndTime: "",
         orderDateVal: [],          // 工单统计时间
         radio: "1",
         radios: "2",
         datas: [],                 //根据年月日进行工单统计
         tiem: "",                  //年月日
         //工单状态
         waiting: "",               //待接工单
         inhand: "",                //处理中
         accomplish: "",            //已完成
         //员工接单排行榜
         tableData: [],             //员工接单排行榜
         tiems: "",                 //年月日
         //客房服务雷达图
         tenanceCounts: "",         //维修数量
         totalCount: "",            //维修总数
         roomCount: "",             //房间数量
         totalCounts: "",           //房间总数
         relativeplot: [],
         cleanKeeping: {
            totalCount: 0,
            roomCount: 0,
         },
         tain: {
            totalCount: 0,
            tenanceCount: 0,
         },
         Guestroomradar: {
            tooltip: {
               trigger: "item",
            },
            series: [
               {
                  type: "pie",
                  radius: "55%",
                  center: ["50%", "60%"],
                  data: [
                     {
                        value: 0,
                        name: "清洁",
                        itemStyle: { normal: { color: "#fc9936" } },
                     },
                     {
                        value: 0,
                        name: "维修",
                        itemStyle: { normal: { color: "#30cbff" } },
                     },
                  ],
                  emphasis: {
                     itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                     },
                  },
               },
            ],
         },
         // 工单统计
         option : {
            xAxis: {
               type: 'category',
               data: []
            },
            yAxis: {
               type: 'value'
            },
            series: [{
               data: [],
               type: 'bar',
               showBackground: true,
               backgroundStyle: {
                  color: 'rgba(0, 0, 0, 0)'
               },
               itemStyle:{
                  normal:{
                     color:'#409eff'
                  }
               },
            }]
         },
         //工程统计图
         // 工单统计
         statistics: [],
         chart : {
            xAxis: {
               type: 'category',
               data: []
            },
            yAxis: {
               type: 'value'
            },
            series: [{
               data: [],
               type: 'bar',
               showBackground: true,
               backgroundStyle: {
                  color: 'rgba(0, 0, 0, 0)'
               },
               itemStyle:{
                  normal:{
                     color:'#409eff'
                  }
               },
            }]
         },
         week: "0",          //工单总数
         dataline: "",       //工单日期
         totalnumber: "",    //工程总数
         getdataline: "",    //工程日期
         repairDate:'WEEK',  //工单数据日期
         dateOrder:'WEEK',   //员工接单排行
         radarDate:'WEEK',    //雷达图日期默认选中
         index:'',            //酒店下标
      };
   },
   mounted() {
      this.staffs("WEEK");
      this.Workorderstatus();
      this.Datestatistics("WEEK");
      this.radarmap("WEEK");
      this.handleOrderDate("WEEK");
      this.handleOrderDates("WEEK");
   },
   computed: {
      ...mapState(['hotelInfo']),
      tenanceCount() {
         let count;
         count = this.cleanKeeping.totalCount + this.tain.totalCount;
         const proportions =
               Math.round((Number(this.tain.totalCount) / count) * 10000) / 100;
         return proportions;
      },
      roomCounts() {
         let count;
         count = this.cleanKeeping.totalCount + this.tain.totalCount;
         // console.log(count)
         const proportion =
               Math.round((Number(this.cleanKeeping.totalCount) / count) * 10000) /
               100;
         return proportion;
      },
   },
   methods: {
      // 按订单统计时间类型过滤
      handleOrderDate(date) {
         this.orderDateType = date;
         this.orderBeginTime = "";
         this.orderEndTime = "";
         this.orderDateVal = [];
         this.godan();
      },
      statu(condition){
         this.$router.push({path: '/work_order_list', query: {condition}})
      },
      // 工单统计时间段过滤
      getOrderDatePacker(val) {
         this.orderDateVal = val;
         if (this.orderDateVal) {
            this.orderBeginTime = this.orderDateVal[0];
            this.orderEndTime = this.orderDateVal[1];
            this.orderDateTypes = "";
         } else {
            this.orderBeginTime = "";
            this.orderEndTime = "";
            this.orderDateTypes = "WEEK";
         }
         this.godan();
      },
      //工程统计时间段过滤
      engineering(val) {
         this.orderDateVal = val;
         if (this.orderDateVal) {
            this.orderBeginTime = this.orderDateVal[0];
            this.orderEndTime = this.orderDateVal[1];
            this.orderDateTypes = ""
         } else {
            this.orderBeginTime = "";
            this.orderEndTime = "";
            this.orderDateTypes = "WEEK";
         }
         this.statisticsa();
      },
      //工程统计
      handleOrderDates(date) {
         this.orderDateTypes = date;
         this.orderBeginTime = "";
         this.orderEndTime = "";
         this.orderDateVal = [];
         this.statisticsa();
      },
      //工程统计图
      statisticsa() {
         let beginDate = ''
         let endDate = ''
         if(this.orderDateVal){
             beginDate = this.orderDateVal[0].substring(0,10)
             endDate = this.orderDateVal[1].substring(0,10)
         }
         const url = urlObj.Timetodate;
         let param = {
            hotelId: this.hotelInfo.id,
            dateType: this.orderDateTypes,
            workType: "2",
            beginDate: beginDate,
            endDate: endDate,
         };
         this.$axios.post(url, param).then((res) => {
            let statisticsa = res.records;
            let arr = [];
            this.chart.series[0].data = [];
            statisticsa.forEach((item) => {
               arr.push(item.date);
               this.chart.series[0].data.push(Number(item.totalCount));
            });
            var weeks = 0;
            for (let i = 0; i < statisticsa.length; i++) {
               weeks += statisticsa[i].totalCount;
            }
            this.totalnumber = weeks;
            if (this.orderDateTypes == "DAY") {
               this.getdataline = "今日工单总数";
            }
            if (this.orderDateTypes == "WEEK") {
               this.getdataline = "本周工单总数";
            }
            if (this.orderDateTypes == "MONTH") {
               this.getdataline = "本月工单总数";
            }

            switch (this.orderDateTypes) {
               case "MONTH":
                  this.chart.xAxis.data = arr
                  .map((item) => {
                     return item;
                  })
                  .sort();
                  break;
               case "WEEK":
                  this.chart.xAxis.data = arr;
                  break;
               case "DAY":
                  this.chart.xAxis.data = arr
                        .map((item) => {
                           return item.split(" ")[1];
                        })
                        .sort();
                  break;
               case "":
                  this.chart.xAxis.data = arr;
                  break;
            }
            this.$nextTick(() => {
               let myChart = this.$echarts.init(document.getElementById("project"));
               myChart.setOption(this.chart, true);
            });
         });
      },
      //根据年月日统计工单信息
      Datestatistics(data) {
         this.tiem = data;
         this.repairDate = this.tiem
         const param = {
            dateType: this.tiem,
            hotelId: this.hotelInfo.id,
         };
         const url = urlObj.Datestatistics;
         this.$axios.post(url, param).then((res) => {
            this.datas = res.records;
            if(!this.datas.hasOwnProperty('totalCount')){
               this.datas.totalCount = 0
            }
            if(!this.datas.hasOwnProperty('urgentCount')){
               this.datas.urgentCount = 0
            }
            if(!this.datas.hasOwnProperty('roomCount')){
               this.datas.roomCount = 0
            }
         });
      },
      //工单状态
      Workorderstatus() {
         const url = hotelMan.Workorderstatus;
         let param = {
            hotelId: this.hotelInfo.id,
         };
         this.$axios.post(url, param).then((res) => {
            if (res.records.length == "0") {
               this.waiting = "0";
               this.inhand = "0";
               this.accomplish = "0";
               return;
            }
            this.waiting = res.records[0].totalCount;
            this.inhand = res.records[1].totalCount;
            this.accomplish = res.records[2].totalCount;
         });
      },
      //员工接单排行榜
      staffs(data) {
         this.tiems = data;
         this.dateOrder = this.tiems
         const url = urlObj.rankingList;
         let param = {
            hotelId: this.hotelInfo.id,
            dateType: this.tiems,
         };
         this.$axios.post(url, param).then((res) => {
            this.tableData = res.records;
         });
      },
      //客房服务雷达图
      radarmap(data) {
         this.tiems = data;
         this.radarDate = this.tiems
         const url = urlObj.radarmap;
         let param = {
            hotelId: this.hotelInfo.id,
            dateType: this.tiems,
         };
         this.$axios.post(url, param).then((res) => {
            this.relativeplot = res.records;
            this.relativeplot.forEach((item) => {
               //这是清洁
               if (item.workType === "1") {
                  this.cleanKeeping = item;
               }
               if (item.workType === "2") {
                  //这是维修
                  this.tain = item;
               }
            });

            if (this.relativeplot.length == "1") {
               if (this.relativeplot[0].workType == "1") {
                  this.cleanKeeping = this.relativeplot[0];
               } else {
                  this.cleanKeeping.totalCount = "0";
               }
               if (this.relativeplot[0].workType == "2") {
                  this.cleanKeeping = this.relativeplot[0];
               } else {
                  this.tain.totalCount = "0";
                  this.Guestroomradar.series[0].data[1].value == "100";
               }
            }

            // 计算百分比
            let count;
            count = this.cleanKeeping.totalCount + this.tain.totalCount;
            const proportions =
                  Math.round((Number(this.tain.totalCount) / count) * 10000) / 100;
            const proportion =
                  Math.round((Number(this.cleanKeeping.totalCount) / count) * 10000) /
                  100;
            this.Guestroomradar.series[0].data[0].value = proportion;
            this.Guestroomradar.series[0].data[1].value = proportions;
            if (this.relativeplot.length == "0") {
               this.Guestroomradar.series[0].data[0].value = "0";
               this.Guestroomradar.series[0].data[1].value = "0";
               this.tain.totalCount = "0";
               this.cleanKeeping.totalCount = "0";
            }
            this.$nextTick(() => {
               let myChart = this.$echarts.init(
                     document.getElementById("Theradaroftheservice")
               );
               myChart.setOption(this.Guestroomradar, true);
            });
         });
      },
      //工单统计
      godan() {
         let beginDate = ''
         let endDate = ''
         if(this.orderDateVal){
             beginDate = this.orderDateVal[0].substring(0,10)
             endDate = this.orderDateVal[1].substring(0,10)
         }
         const url = urlObj.Timetodate;
         let param = {
            hotelId: this.hotelInfo.id,
            dateType: this.orderDateType,
            workType: "1",
            beginDate: beginDate,
            endDate: endDate,
         };
         this.$axios.post(url, param).then((res) => {
            let statistics = res.records;
            this.ms = res.records;
            let arr = [];
            this.option.series[0].data = [];
            statistics.forEach((item) => {
               arr.push(item.date);
               this.option.series[0].data.push(Number(item.totalCount));
            });
            let week = 0;
            for (let i = 0; i < statistics.length; i++) {
               week += statistics[i].totalCount;
            }
            this.week = week;
            if (this.orderDateType == "DAY") {
               this.dataline = "今日工单总数";
            }
            if (this.orderDateType == "WEEK") {
               this.dataline = "本周工单总数";
            }
            if (this.orderDateType == "MONTH") {
               this.dataline = "本月工单总数";
            }
            switch (this.orderDateType) {
               case "MONTH":
                  this.option.xAxis.data = arr
                        .map((item) => {
                           return item;
                        })
                        .sort();
                  break;
               case "WEEK":
                  this.option.xAxis.data = arr;
                  break;
               case "DAY":
                  this.option.xAxis.data = arr
                        .map((item) => {
                           return item.split(" ")[1];
                        })
                        .sort();
                  break;
               case "":
                  this.option.xAxis.data = arr;
                  break;
            }
            this.$nextTick(() => {
               let myChart = this.$echarts.init(
                     document.getElementById("order-line")
               );
               myChart.setOption(this.option, true);
            });
         });
      },
      //工单管理
      workorder() {
         this.$router.push("/work_order_list");
      },
      //人员管理
      staff() {
         this.$router.push("/user_manage");
      },
      //登录日志
      journal() {
         this.$router.push("/login_log");
      },
      //员工接单排行明细
      handleDetail(routes,rows){
         let row = rows.receiveId
         this.$router.push({path: '/edit', query: {routes,row}})
         // sessionStorage.removeItem('personalDatas')
      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.staffs("WEEK");
               this.Workorderstatus();
               this.Datestatistics("WEEK");
               this.radarmap("WEEK");
               this.handleOrderDate("WEEK");
               this.handleOrderDates("WEEK");
            }
         },
         deep: true
      },
   },
};
</script>

<style scoped>
/*. {*/
/*  width: 99%;*/
/*  height: 1600px;*/
/*  padding: 50px 100px 50px 100px;*/
/*  background: white;*/
/*  margin: 10px;*/
/*}*/
.box {
  width: 100%;
}
.quantity {
  width: auto;
  height: auto;
  border: 1px solid #a9a9a9;
  padding: 0px 20px;
  margin: 0px 20px;
  padding-bottom: 20px;
}
.quantity div img {
  float: left;
  vertical-align: middle;
  width: 40px;
}
.quantity div {
  margin-top: 30px;
}
.Workordernumber {
  width: 80%;
  display: flex;
  margin-top: 30px;
}
.boxStatus {
  /*width: 75%;*/
  min-width: 1430px;
  border: 1px solid #ccc;
  height: auto;
  margin-top: 50px;
}
.statistics {
  max-width: 73%;
  min-width: 1050px;
  border: 1px solid #ccc;
  height: 355px;
  margin-top: 30px;
}
.status .title {
  height: 40px;
  background: #f3f3f3;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  line-height: 40px;
  padding-left: 10px;
}
.status .workorder div {
  width: 30%;
  float: left;
  margin: 5% 0px;
}
.status .workorder p {
  width: 50%;
  float: left;
  text-align: center;
}
.status .workorder span {
  width: 50%;
  display: inline-block;
  float: right;
  text-align: center;
}
.interlinkage {
  display: flex;
}
.interlinkage div {
  width: 70px;
  height: 60px;
  margin: 40px 40px;
  text-align: center;
  cursor: pointer;
}
.interlinkage div img {
  width: 40px;
}
.interlinkage div p a {
  font-size: 13px;
}
.Workordernumbers {
  margin-top: 20px !important;
  margin-left: 5rem;
}
.Workordernumbers p {
  font-size: 15px;
}
.status .left {
  width: 20%;
  height: 312px;
  border-right: 1px solid #ccc;
  float: left;
}
.thismonth {
  text-align: center;
  padding-top: 20px;
}
.thisweek {
  text-align: center;
  padding-top: 30px;
}

.order-date {
  display: flex;
  width: 75%;
  padding-left: 20px;
}

.period {
  padding-left: 20px;
  width: 20%;
  color: #b3b3b3;
  margin-top: 10px;
}

.thismonth p span {
  color: #49c182;
}
.thisweek p span {
  color: #f1524f;
}
.tiems {
  color: #ccc;
}
.figure {
  font-size: 23px;
}
.radar {
  width: 22%;
  height: 350px;
  border: 1px solid #afafaf;
  margin-left: 3%;
}
.radars {
  /*width: 22%;*/
  min-width: 315px;
  max-width: 22%;
  height: 350px;
  border: 1px solid #afafaf;
  margin-left: 3%;
  margin-top: 30px;
}
.titles {
  height: 40px;
  background: #f3f3f3;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

/* 选中后的字体颜色 */
.el-radio__input.is-checked + .el-radio__label {
  color: #000 !important;
}
/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
  background: #30cbff !important;
  border-color: #9dabaf !important;
}
.clean p .is-checked .el-radio__input.is-checked {
  color: black;
}
.clean p .el-radio__input.is-checked .el-radio__inner {
  background: #ff9933 !important;
  border-color: #9dabaf !important;
}
.btn {
  margin-top: 20px;
  padding-left: 2rem;
}
.tain {
  display: flex;
  margin: 20px 0px;
}
.tain p {
  width: 33.3%;
  text-align: center;
  font-size: 14px;
}
.clean {
  display: flex;
  margin: 20px 0px;
}
.clean p {
  width: 33.3%;
  text-align: center;
  font-size: 14px;
}
.unit {
  margin-left: 2.7rem;
}
.unit p {
  float: left;
  font-size: 14px;
}
.has-gutter tr th {
  text-align: center;
}
tbody .el-table__row td {
  text-align: center;
}
.radar {
  position: relative;
  top: 2rem;
  min-width: 315px;
  max-width: 22%;
}
.search {
  display: flex;
  margin: 20px 0px;
}

.label {
  font-size: 14px;
  margin-top: 3px;
}
.pop-top {
  display: none;
}
.cous{cursor: pointer;}
</style>

/* 酒店管家 */
export const hotelMan = {
   // 工单分类列表
   workOrderCategory: '/hotelkeeper/workType/getList',
   // 新增分类
   Thenewtype: '/hotelkeeper/workType/save',
   // 删除分类
   delCategory: '/hotelkeeper/workType/delete',
   // 新增工单
   addworkorder: '/hotelkeeper/workorderinfo/save',
   // 工单详情
   Workorderdetails:'/hotelkeeper/workorderinfo/getWorkOrderInfoDetail',
   // 删除工单
   delWorkOrder: '/hotelkeeper/workorderinfo/delete',
   // 工单列表
   workOrders: '/hotelkeeper/workorderinfo/getPages',

   // 分页获取工单列表
   getWorkOrders: '/hotelkeeper/workinfo/getPages',
   // 发布工单需求
   publishWorkOrders: '/hotelkeeper/workinfo/save',
   // 分页获取工单类别
   getWorkCategories: '/hotelkeeper/workType/list',
   // 保存工单分类
   saveWorkCategory: '/hotelkeeper/workType/save',
   // 分页获取酒店结算列表
   getHotelPages: '/hotelkeeper/settlementInfo/getHotelPages',
   // 获取结算明细列表
   getSettlementWorkList: '/hotelkeeper/settlementInfo/getSettlementWorkList',
   // 分页获取机构结算列表
   getServicePages: '/hotelkeeper/settlementInfo/getServicePages',
   // 创建结算单
   createSettlement: '/hotelkeeper/settlementInfo/create',
   // 工单状态列表
   workInfoFlow: '/hotelkeeper/workInfoFlow/getList',
   // 获取绑定银行卡列表
   bankCardList: '/member/bank/getBankListByUserId',
   // 修改工单状态
   workStatus: '/hotelkeeper/workinfo/updateWorkStatus',
   // 立即付款
   updateSettlementStatus: '/hotelkeeper/settlementInfo/updateSettlementStatus',
   // 获取工单详情
   workInfoDetail: '/hotelkeeper/workinfo/getWorkInfoDetail',
   // 员工考核列表
   assessments:'/hotelkeeper/workorderinfo/getStatisticsWorkOrderPages',
   // 员工考核详情列表
   assessmentDetails: '/hotelkeeper/workorderinfo/getPages',
   //工单状态
   Workorderstatus:'/hotelkeeper/workorderinfo/statisticsWorkOrderByStatus',
}

/* 智能问答 */
export const smartFaq = {
   // 获取问题分类
   questionCategories: '/smartfaq/category/getList',
   // 新增问题分类
   addQuestionCategory: '/smartfaq/category/add',
   // 修改问题分类
   updateQuestionCategory: '/smartfaq/category/update',
   // 删除问题分类
   delQuestionCategory: '/smartfaq/category/delete',
   // 获取问题描述列表
   questionDescribes: '/smartfaq/question/getPage',
   // 新增问题描述
   addQuestionDescribe: '/smartfaq/question/add',
   // 编辑问题描述
   editQuestionDescribe: '/smartfaq/question/update',
   // 删除问题描述项
   delQuestionDescribe: '/smartfaq/question/deleteQuestion',
   // 获取答案列表
   answerList: '/smartfaq/answer/getList',
   // 新增答案
   addAnswer: '/smartfaq/answer/add',
   // 获取词库列表
   thesaurus: '/smartfaq/faqWord/getList',
   // 获取词条列表
   entries: '/smartfaq/faqWord/getLeafList',
   // 添加词库词条
   addFaqWord: '/smartfaq/faqWord/saveFaqWord',
   // 批量添加词库
   addBatchThesaurus: '/smartfaq/faqWord/batchSave',
   // 删除词库词条
   delFaqWord: '/smartfaq/faqWord/delete',
   // 编辑词库词条
   updateFaqWord: '/smartfaq/faqWord/update',
   // 启动词条
   enableFaqWord: '/smartfaq/faqWord/setActive',
   // 禁用词条
   disableFaqWord: '/smartfaq/faqWord/setForbidden',
}
